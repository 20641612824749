<script setup lang="ts">
import { onMounted } from 'vue';
import { EventCollectionImpression, emitEvent } from '../helper/tracking-events';
import { ProductData } from '../models/product';

const props = defineProps({
  collection: {
    type: String,
  },
  products: {
    type: Array<ProductData>,
    default: () => []
  }
})

onMounted(() => {
  emitEvent(EventCollectionImpression, {
    collection: props.collection,
    products: props.products.map(p => {
      let attributes: { [key: string]: string } = {}
      for (let [key, attribute] of Object.entries(p.attributes)) {
        attributes[key] = attribute.value
      }

      return {
        ID: p.ID,
        name: p.name,
        variants: p.variants.map(variant => {
          let variantAttributes: { [key: string]: string } = {}
          for (let [key, attribute] of Object.entries(variant.attributes)) {
            variantAttributes[key] = attribute.value
          }

          return {
            ID: variant.ID,
            name: p.name,
            price: variant.price,
            attributes: variantAttributes,
          }
        }),
        price: p.price,
        attributes: attributes,
      }
    })
  })
})
</script>
