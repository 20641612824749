<template>
  <article class="m-blog__card u-col-span:4@md" v-for="article of props.articles">
    <a :href="article.url" class="m-blog__link">
      <picture v-if="article.overview_image" class="a-image a-image--contain a-image--center-center" style="--padding-ratio: calc(200/400)">
        <img class="a-image__img" :src="article.overview_image + '?width=5&height=5' ?? ''"  :data-src="article.overview_image + '?width=400&height=200' ?? ''" loading="lazy" />
      </picture>

      <div class="m-blog__info">
        <span class="m-blog__date">{{ displayDate(article.created_at) }}</span>
        <h2 class="m-blog__title a-h3">{{ article.title }}</h2>
        <p class="m-blog__description" v-html="article.summary"></p>
      </div>
    </a>
  </article>
</template>
<script setup lang="ts">

function displayDate(dateString: string): string {
  const date: Date = new Date(dateString)
  return date.toLocaleDateString('default', { day: '2-digit', month: 'long', year: 'numeric' })
}

const props = defineProps({
  articles: {
    type: Array<Article>,
    default: [],
    required: true,
  },
})

interface Article {
  title: string;
  summary: string;
  url: string;
  overview_image: string;
  created_at: string;
}

</script>
